<script lang="ts" setup>
  import { useHead, useSeoMeta } from '@unhead/vue';

  useHead({
    titleTemplate: '%s',
  });

</script>

<template>
  <RouterView />
</template>

<style>
html {
    height: 100%;
}
</style>
